import axios from 'axios'
import store from 'store'
import { message, notification } from 'antd'
import { history } from 'index'
import { put } from 'redux-saga/effects'

const {href: host} = window.location
export const isLocalEnvironment = host.includes('localhost')
export const isTestEnvironment = host.includes('localhost') || host.includes('surge')
const apiClient = axios.create({

  baseURL: isTestEnvironment ? 
  'https://api.zimcserver.com/zero-api/public/api/' :
  // 'https://ziapi.techend.com.ng/api/':
  'https://ziapi.techend.com.ng/api/'
  
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const error401 = JSON.stringify(error).includes('401')
  if(error401){
    history.push('/auth/login')
    store.clearAll()
    put({
      type: 'user/SET_STATE',
      payload: {
        id: '',
        name: '',
        email: '',
        avatar: '',
        authorized: false,
        userStatus: {},
        localData: {},
        loading: false,
      },
    })
    message.error({
      content: "Token has expired, please re-login to continue.",
      duration: 4,
      key: "updatable"
    })
  }else{
    notification.error({
      message: 'Error!',
      description: String(response || error)
    })
  }
})


export const formatCurrency = (num) => {
  if (num !== undefined) {
      return parseFloat(num)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return 0
}

export default apiClient
